<template>
  <div class="hold-transition">
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Inspecciones</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">TEP</li>
                  <li class="breadcrumb-item active">Operaciones</li>
                  <li class="breadcrumb-item active">Inspecciones</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="col-6 pt-2 pb-2">
                <h5>Inspecciones</h5>
              </div>
              <ul id="myTab" role="tablist" class="nav nav-tabs">
                <li class="nav-item">
                  <a
                    id="tab-Principal"
                    data-toggle="tab"
                    href="#Principal"
                    class="nav-link active"
                    @click="cambiaTab('principal')"
                    >Principal</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    id="tab-Gestion"
                    data-toggle="tab"
                    href="#Gestion"
                    class="nav-link"
                    @click="cambiaTab('gestiona')"
                    v-if="inspeccion_id"
                    >Gestionar Inspección</a
                  >
                </li>
              </ul>
              <div id="myTabContent" class="tab-content">
                <!-- Formulario -->
                <div id="Principal" class="tab-pane fade active show">
                  <div class="card-body">
                    <div class="row">
                      <div class="form-group col-md-4">
                        <label>Turno</label>
                        <v-select
                          :class="
                            $store.getters.getDarkMode ? 'dark-vselect' : ''
                          "
                          v-model="form.turno"
                          placeholder="turno_id"
                          label="nombre"
                          :options="listasForms.turnos"
                          :disabled="estado_documento == 2 || preoperacional"
                          @input="seleccionarTurno"
                        ></v-select>
                      </div>
                      <div class="form-group col-md-1">
                        <label>SETT</label>
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="form.tep_viaje_id"
                          readonly
                        />
                      </div>
                      <div class="form-group col-md-2 text-center">
                        <label>Pre-operacional</label>
                        <input
                          type="checkbox"
                          class="form-control form-control-sm p-0"
                          v-model="preoperacional"
                          :disabled="form.formato_inspeccion"
                          @click="limpiarCampos('preoperacional')"
                        />
                      </div>

                      <div class="form-group col-md-2">
                        <label>Sitio</label>
                        <v-select
                          :class="
                            $store.getters.getDarkMode ? 'dark-vselect' : ''
                          "
                          v-model="form.sitio"
                          placeholder="Sitio"
                          label="nombre"
                          :options="listasForms.sitios"
                          :disabled="estado_documento == 2"
                          @input="seleccionarSitio"
                        ></v-select>
                      </div>

                      <div class="form-group col-md-3">
                        <label>Formato de Inspección</label>
                        <v-select
                          :class="[
                            $v.form.formato_inspeccion.$invalid
                              ? 'is-invalid'
                              : 'is-valid',
                            $store.getters.getDarkMode ? 'dark-vselect' : '',
                          ]"
                          v-model="form.formato_inspeccion"
                          placeholder="Formato Inspección"
                          label="nombre"
                          class="form-control form-control-sm p-0"
                          :options="listasForms.formatos_inspeccion"
                          :filterable="false"
                          :disabled="
                            preoperacionalDisabled || estado_documento == 2
                          "
                          @search="buscarFormatosInspeccion"
                        ></v-select>
                      </div>
                    </div>
                    <div class="row">
                      <div class="form-group col-md-2">
                        <label>Vehiculo</label>
                        <v-select
                          :class="[
                            $v.form.vehiculo.$invalid
                              ? 'is-invalid'
                              : 'is-valid',
                            $store.getters.getDarkMode ? 'dark-vselect' : '',
                          ]"
                          v-model="form.vehiculo"
                          placeholder="Placa"
                          label="placa"
                          class="form-control form-control-sm p-0"
                          :options="listasForms.vehiculos"
                          :filterable="false"
                          :disabled="estado_documento == 2"
                          @search="buscarvehiculos"
                        ></v-select>
                        <div v-if="form.vehiculo">
                          <div
                            v-if="
                              form.vehiculo.ultimo_registro &&
                              form.vehiculo.ultimo_registro != ''
                            "
                          >
                            <span
                              class="badge badge-warning"
                              v-if="
                                form.vehiculo.ultimo_registro
                                  .fecha_hora_ultimo_reporte < fechaCorte
                              "
                            >
                              Ultimo reporte:
                              {{
                                form.vehiculo.ultimo_registro
                                  .fecha_hora_ultimo_reporte
                              }}
                            </span>
                            <span class="badge badge-success" v-else>
                              Ultimo reporte:
                              {{
                                form.vehiculo.ultimo_registro
                                  .fecha_hora_ultimo_reporte
                              }}
                            </span>
                          </div>
                          <div v-else>
                            <span class="badge badge-danger"
                              >No presenta fecha de reporte</span
                            >
                          </div>
                        </div>
                      </div>

                      <div class="form-group col-md-3">
                        <label>Empresa</label>
                        <v-select
                          :class="[
                            $v.form.empresa.$invalid
                              ? 'is-invalid'
                              : 'is-valid',
                            $store.getters.getDarkMode ? 'dark-vselect' : '',
                          ]"
                          v-model="form.empresa"
                          placeholder="Empresa"
                          label="razon_social"
                          class="form-control form-control-sm p-0"
                          :options="listasForms.empresas"
                          :filterable="false"
                          :disabled="estado_documento == 2"
                          @search="buscarEmpresas"
                        ></v-select>
                      </div>
                      <div
                        class="form-group col-md-4"
                        v-if="
                          (viajeInspeccion != undefined &&
                            viajeInspeccion.solicitudes.tipo_operacion != 3) ||
                          turno != undefined
                        "
                      >
                        <label>Conductor</label>
                        <v-select
                          :class="[
                            $v.form.conductor.$invalid
                              ? 'is-invalid'
                              : 'is-valid',
                            $store.getters.getDarkMode ? 'dark-vselect' : '',
                          ]"
                          v-model="form.conductor"
                          placeholder="Conductor"
                          label="nombre"
                          class="form-control form-control-sm p-0"
                          :options="listasForms.conductores"
                          :filterable="false"
                          :disabled="!form.empresa || estado_documento == 2"
                          @search="buscarConductor"
                        ></v-select>
                      </div>
                    </div>
                    <div class="row" v-if="viaje && form.vehiculo">
                      <div class="form-group col-md-4">
                        <label>Sitio Cargue</label>
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          :value="viaje.sitio_origen"
                          readonly
                        />
                      </div>
                      <div class="form-group col-md-4">
                        <label>Sitio Desargue</label>
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          :value="viaje.sitio_destino"
                          readonly
                        />
                      </div>
                      <div class="form-group col-md-4">
                        <label>Despachado a</label>
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          :value="viaje.despachado_a"
                          readonly
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div class="form-group col-md-3">
                        <label for="fecha_inicio">Fecha Inicial</label>
                        <input
                          type="date"
                          class="form-control form-control-sm"
                          v-model="form.fecha_inicio"
                          readonly
                        />
                      </div>
                      <div class="form-group col-md-3">
                        <label for="hora_inicio">Hora Inicial</label>
                        <!-- <input
                          type="time"
                          class="form-control form-control-sm"
                          v-model="form.hora_inicio"
                          readonly
                        /> -->
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="form.hora_inicio"
                          :class="
                            $v.form.hora_inicio.$invalid
                              ? 'is-invalid'
                              : 'is-valid'
                          "
                          v-mask="{
                            mask: '99:99',
                            hourFormat: '24',
                          }"
                          readonly
                        />
                      </div>
                      <div class="form-group col-md-3">
                        <label for="fecha_fin">Fecha Final</label>
                        <input
                          type="date"
                          class="form-control form-control-sm"
                          v-model="form.fecha_fin"
                          readonly
                        />
                      </div>
                      <div class="form-group col-md-3">
                        <label for="hora_fin">Hora Final</label>
                        <!-- <input
                          type="time"
                          class="form-control form-control-sm"
                          v-model="form.hora_fin"
                          readonly
                        /> -->
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="form.hora_fin"
                          :class="
                            $v.form.hora_fin.$invalid
                              ? 'is-invalid'
                              : 'is-valid'
                          "
                          v-mask="{
                            mask: '99:99',
                            hourFormat: '24',
                          }"
                          readonly
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div class="form-group col-md-3">
                        <label>Responsable</label>
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          :value="responsable.nombre"
                          readonly
                        />
                        <input
                          v-model="form.responsable_id"
                          type="hidden"
                          class="form-control form-control-sm p-0"
                        />
                      </div>
                      <div class="form-group col-md-6">
                        <label>Observaciones</label><br /><textarea
                          vtype="date"
                          rows="2"
                          class="form-control form-control-sm"
                          v-model="form.observaciones"
                          :disabled="estado_documento == 2"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Gestión de inspecciones -->
                <div
                  id="Gestion"
                  class="tab-pane fade"
                  v-if="inspeccion_id && formato_inspeccion"
                >
                  <TepInspeccionGestiona
                    ref="TepInspeccionGestiona"
                  ></TepInspeccionGestiona>
                  <TepInspeccionImages></TepInspeccionImages>
                </div>
              </div>
              <div class="card-footer">
                <div class="row">
                  <div class="col-md-3">
                    <button class="btn btn-lg bg-secondary" @click="back()">
                      <i class="fas fa-reply"></i><br />Volver
                    </button>
                    <button
                      v-if="estado_documento == 2"
                      class="btn btn-lg bg-navy"
                      @click="irTepTurnos()"
                    >
                      <i class="fas fa-reply"></i><br />Tep Turnos
                    </button>

                    <button
                      class="btn btn-lg bg-primary"
                      v-show="
                        $store.getters.can('tep.inspecciones.edit') &&
                        !$v.form.$invalid &&
                        tab == 'principal' &&
                        (estado_documento == 1 || estado_documento == null)
                      "
                      @click="save"
                    >
                      <i class="fas fa-paper-plane"></i><br />Guardar
                    </button>

                    <button
                      class="btn btn-lg bg-primary"
                      v-if="
                        $store.getters.can('tep.inspecciones.edit') &&
                        estado_documento == 1 &&
                        tab == 'gestiona'
                      "
                      @click="$refs.TepInspeccionGestiona.save()"
                    >
                      <i class="fas fa-paper-plane"></i><br />Guardar
                    </button>
                  </div>
                  <div
                    class="col-md-4"
                    v-if="
                      $store.getters.can('tep.inspecciones.edit') &&
                      estado_documento == 1 &&
                      tab == 'gestiona'
                    "
                  >
                    <label class="m-0">Estado:</label>
                    <select
                      class="form-control form-control-sm"
                      v-model="
                        $refs.TepInspeccionGestiona.estadoForm.estado_documento
                      "
                      v-if="
                        $store.getters.can('tep.inspecciones.edit') &&
                        estado_documento == 1 &&
                        tab == 'gestiona'
                      "
                      @change="$refs.TepInspeccionGestiona.save()"
                      :disabled="
                        ($store.getters.can('tep.inspecciones.edit') &&
                          estado_documento != 1) ||
                        !$refs.TepInspeccionGestiona.flagGestionado
                      "
                    >
                      <option
                        v-for="estado in $refs.TepInspeccionGestiona.listaForm
                          .estados_documento"
                        :key="estado.numeracion"
                        :value="estado.numeracion"
                      >
                        {{ estado.descripcion }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import moment from "moment";
import TepInspeccionGestiona from "./TepInspeccionGestiona";
import TepInspeccionImages from "./TepInspeccionImages";
import vSelect from "vue-select";
import { required, helpers } from "vuelidate/lib/validators";
const timeFormat = helpers.regex(
  "timeFormat",
  /^([0-1]?[0-9]|2[0-3]):([0-5][0-9])(:[0-5][0-9])?$/
);
export default {
  name: "TepInspeccionForm",
  components: {
    TepInspeccionGestiona,
    TepInspeccionImages,
    vSelect,
  },
  data() {
    return {
      tab: "principal",
      responsable: {
        id: this.$store.getters.getUser.id,
        nombre: this.$store.getters.getUser.name,
      },
      formato_inspeccion: null,
      detalles_inspecciones: null,
      inspeccion_id: null,
      fechaActual: null,
      fechaCorte: null,
      estado: null,
      estado_documento: null,
      viaje_id: null,
      viaje: null,
      viajeInspeccion: null,
      inspeccion: null,
      turno: null,
      solicitud: null,
      form: {
        id: null,
        tep_viaje_id: null,
        sitio: null,
        sitio_id: null,
        formato_inspeccion: null,
        vehiculo: null,
        turno: {
          nombre: null,
        },
        turno_id: null,
        empresa: null,
        conductor: null,
        fecha_inicio: null,
        hora_inicio: null,
        fecha_fin: null,
        hora_fin: null,
        observaciones: null,
        responsable_id: this.$store.getters.getUser.id,
      },
      preoperacional: false,
      preoperacionalDisabled: false,
      listasForms: {
        formatos_inspeccion: [],
        empresas: [],
        conductores: [],
        sitios: [],
        turnos: [],
        vehiculos: [],
      },
    };
  },
  validations() {
    if (this.$route.params.turno) {
      if (this.preoperacional) {
        return {
          form: {
            formato_inspeccion: {
              required,
            },
            vehiculo: {
              required,
            },
            empresa: {
              required,
            },
            conductor: {
              required,
            },
            hora_inicio: {
              timeFormat,
            },
            hora_fin: {
              timeFormat,
            },
          },
        };
      } else {
        return {
          form: {
            formato_inspeccion: {
              required,
            },
            vehiculo: {
              required,
            },
            empresa: {
              required,
            },
            conductor: {
              required,
            },
            hora_inicio: {
              timeFormat,
            },
            hora_fin: {
              timeFormat,
            },
          },
        };
      }
    } else if (this.$route.params.viaje) {
      if (this.$route.params.viaje.solicitudes.tipo_operacion != 3) {
        if (this.preoperacional) {
          return {
            form: {
              formato_inspeccion: {
                required,
              },
              vehiculo: {
                required,
              },
              empresa: {
                required,
              },
              conductor: {
                required,
              },
              hora_inicio: {
                timeFormat,
              },
              hora_fin: {
                timeFormat,
              },
            },
          };
        } else {
          return {
            form: {
              formato_inspeccion: {
                required,
              },
              vehiculo: {
                required,
              },
              empresa: {
                required,
              },
              conductor: {
                required,
              },
              hora_inicio: {
                timeFormat,
              },
              hora_fin: {
                timeFormat,
              },
            },
          };
        }
      } else {
        if (this.preoperacional) {
          return {
            form: {
              formato_inspeccion: {
                required,
              },
              vehiculo: {
                required,
              },
              empresa: {
                required,
              },
              conductor: {},
              hora_inicio: {
                timeFormat,
              },
              hora_fin: {
                timeFormat,
              },
            },
          };
        } else {
          return {
            form: {
              formato_inspeccion: {
                required,
              },
              vehiculo: {
                required,
              },
              empresa: {
                required,
              },
              conductor: {},
              hora_inicio: {
                timeFormat,
              },
              hora_fin: {
                timeFormat,
              },
            },
          };
        }
      }
    } else if (this.turnos == undefined || this.viajeInspeccion == undefined) {
      if (this.preoperacional) {
        return {
          form: {
            formato_inspeccion: {
              required,
            },
            vehiculo: {
              required,
            },
            empresa: {
              required,
            },
            conductor: {
              required,
            },
            hora_inicio: {
              timeFormat,
            },
            hora_fin: {
              timeFormat,
            },
          },
        };
      } else {
        return {
          form: {
            formato_inspeccion: {
              required,
            },
            vehiculo: {
              required,
            },
            empresa: {
              required,
            },
            conductor: {
              required,
            },
            hora_inicio: {
              timeFormat,
            },
            hora_fin: {
              timeFormat,
            },
          },
        };
      }
    }
  },
  methods: {
    async getIndex() {
      this.inspeccion_id = this.$route.params.inspeccion_id;
      this.viajeInspeccion = this.$route.params.viaje;
      this.inspeccion = this.$route.params.inspeccion;
      this.turno = this.$route.params.turno;
      if (this.inspeccion_id !== undefined) {
        await axios
          .get("/api/tep/inspecciones/show", {
            params: {
              inspeccion_id: this.inspeccion_id,
            },
          })
          .then((response) => {
            if (response.data.length > 0) {
              let result = response.data[0];

              this.estado = result.estado;
              this.estado_documento = result.estado_documento;
              this.formato_inspeccion = result.formato_inspeccion;
              this.detalles_inspecciones = result.detalles_inspecciones;
              this.form.tep_viaje_id = result.tep_viaje_id;
              if (result.tep_turno !== null) {
                if (
                  result.tep_turno.solicitudes !== null &&
                  result.tep_turno.solicitudes !== ""
                ) {
                  this.form.tep_viaje_id = result.tep_viaje_id;
                } else {
                  this.form.tep_viaje_id = "";
                }
              }

              if (result.formato_inspeccion.pre_operacional == 1) {
                this.preoperacional = true;
              }

              // Datos del viaje
              /* if (result.viaje) {
              this.viaje = {
                sitio_origen: result.viaje.guia.det_guia.sitio_origen.nombre,
                sitio_destino: result.viaje.guia.det_guia.sitio_destino.nombre,
                despachado_a: result.viaje.guia.det_guia.despachado_a,
              };
            }*/

              // Se verifica el responsable de la inspección
              let responsable = this.form.responsable_id;
              if (result.responsable_id) {
                responsable = result.responsable.id;
                this.responsable = {
                  id: result.responsable.id,
                  nombre: result.responsable.name,
                };
              }

              // Se filtra el punto de gestion

              let sitio = null;

              if (result.sitio != null) {
                let nConductor = null;
                if (result.tep_turno.conductor) {
                  nConductor =
                    result.tep_turno.conductor.nombres +
                    " " +
                    result.tep_turno.conductor.apellidos;
                }

                this.form = {
                  id: result.id,
                  tep_viaje_id: result.tep_viaje_id ? result.tep_viaje_id : "",
                  formato_inspeccion: {
                    id: result.formato_inspeccion.id,
                    nombre: result.formato_inspeccion.nombre,
                    pre_operacional: result.formato_inspeccion.pre_operacional,
                  },
                  sitio: {
                    id: result.sitio.id,
                    nombre: result.sitio.nombre,
                  },
                  empresa: {
                    id: result.empresa.id,
                    razon_social: result.empresa.razon_social,
                  },
                  conductor: {
                    id: result.conductor ? result.conductor.id : "",
                    nombre: `${
                      result.conductor ? result.conductor.nombres : ""
                    } ${result.conductor ? result.conductor.apellidos : ""}`,
                  },
                  vehiculo: {
                    id: result.vehiculo.id,
                    placa: result.vehiculo.placa,
                    tipo_vehiculo_id: result.vehiculo.tipo_vehiculo_id,
                  },
                  turno: {
                    nombre:
                      nConductor +
                      " " +
                      result.tep_turno.id +
                      " " +
                      result.tep_turno.fecha,
                  },
                  turno_id: result.tep_turno_id ? result.tep_turno_id : "",
                  fecha_inicio: result.fecha_inicio,
                  hora_inicio: result.hora_inicio,
                  fecha_fin: result.fecha_fin,
                  hora_fin: result.hora_fin,
                  observaciones: result.observaciones,
                  responsable_id: responsable,
                };
              }
            }
          });
      } else {
        if (this.turno !== undefined) {
          //faltan datos formato
          if (this.inspeccion !== undefined) {
            this.form.turno = {
              nombre: this.turno
                ? this.turno.conductor.nombres +
                  " " +
                  this.turno.conductor.apellidos +
                  " " +
                  this.turno.id +
                  " " +
                  this.turno.fecha
                : "",
            };
            this.form.turno_id = this.turno ? this.turno.id : "";
            if (this.inspeccion.pre_operacional == 1) {
              this.preoperacional = true;
            }
            this.form.formato_inspeccion = this.inspeccion;
            this.form.vehiculo = this.turno.vehiculo;

            this.form.empresa =
              this.turno.postulacion.tep_det_nominacion.tep_transportadora;
            this.form.conductor = this.turno.conductor;
            this.form.conductor.nombre =
              this.form.conductor.nombres + " " + this.form.conductor.apellidos;

            this.form.sitio = this.turno.sitio;
            this.form.sitio_id = this.form.sitio.id;
          } else {
            this.preoperacional = true;
            this.form.formato_inspeccion = this.inspeccion;
            this.form.vehiculo = this.turno.vehiculo;
            this.form.turno = {
              nombre: this.turno
                ? this.turno.conductor.nombres +
                  " " +
                  this.turno.conductor.apellidos +
                  " " +
                  this.turno.id +
                  " " +
                  this.turno.fecha
                : "",
            };
            this.form.turno_id = this.turno ? this.turno.id : "";
            this.form.empresa =
              this.turno.postulacion.tep_det_nominacion.tep_transportadora;
            this.form.empresa_id = this.form.empresa.id;
            this.form.conductor = this.turno.conductor;
            this.form.conductor.nombre =
              this.form.conductor.nombres + " " + this.form.conductor.apellidos;

            this.form.sitio = this.turno.sitio;
            this.form.sitio_id = this.form.sitio.id;
          }
        }
        if (
          this.viajeInspeccion !== null ||
          this.viajeInspeccion !== undefined
        ) {
          if (this.inspeccion !== undefined) {
            this.form.tep_viaje_id = this.viajeInspeccion
              ? this.viajeInspeccion.id
              : "";

            if (this.inspeccion.pre_operacional == 1) {
              this.preoperacional = true;
            }

            this.form.turno = {
              nombre: this.viajeInspeccion
                ? this.viajeInspeccion.conductor.nombres +
                  " " +
                  this.viajeInspeccion.conductor.apellidos +
                  " " +
                  this.viajeInspeccion.id +
                  " " +
                  this.viajeInspeccion.fecha_ini
                : "",
            };

            this.form.turno_id = this.viajeInspeccion.solicitudes.tep_turno_id
              ? this.viajeInspeccion.solicitudes.tep_turno_id
              : "";
            this.form.formato_inspeccion = this.inspeccion;
            this.form.vehiculo = this.viajeInspeccion.vehiculo;

            this.form.empresa = this.viajeInspeccion.transportadora;
            this.form.conductor = this.viajeInspeccion.conductor;
            this.form.conductor.nombre =
              this.form.conductor.nombres + " " + this.form.conductor.apellidos;

            this.form.sitio = this.viajeInspeccion.solicitudes.sitio;
            this.form.sitio_id = this.form.sitio ? this.form.sitio.id : "";
          } else {
            let nConductor = null;
            if (this.viajeInspeccion.conductor) {
              nConductor =
                this.viajeInspeccion.conductor.nombres +
                " " +
                this.viajeInspeccion.conductor.apellidos;

              this.form.conductor = this.viajeInspeccion.conductor;

              this.form.conductor.nombre =
                this.form.conductor.nombres +
                " " +
                this.form.conductor.apellidos;
            }

            this.form.tep_viaje_id = this.viajeInspeccion
              ? this.viajeInspeccion.id
              : "";
            this.preoperacional = true;
            this.form.formato_inspeccion = this.inspeccion;
            this.form.turno = {
              nombre:
                nConductor +
                " " +
                this.viajeInspeccion.id +
                " " +
                this.viajeInspeccion.fecha_ini,
            };
            this.form.turno_id = this.viajeInspeccion.solicitudes.tep_turno_id
              ? this.viajeInspeccion.solicitudes.tep_turno_id
              : "";
            this.form.vehiculo = this.viajeInspeccion.vehiculo;

            this.form.empresa = this.viajeInspeccion.transportadora;
            this.form.empresa_id = this.form.empresa.id;

            this.form.sitio = this.viajeInspeccion.solicitudes.sitio;
            this.form.sitio_id = this.form.sitio ? this.form.sitio.id : "";
          }
        }
      }
    },

    limpiarCampos(preoperacion = null) {
      this.form.sitio = null;
      this.form.sitio_id = null;
      this.form.formato_inspeccion = null;
      this.form.vehiculo = null;
      this.listasForms.formatos_inspeccion = [];
      this.listasForms.vehiculos = [];
      if (preoperacion) {
        this.form.turno = {};
        this.form.turno_id = null;
      }
      this.viaje = null;
    },

    //lista sitio type

    // getSitioType() {
    //   // sitio 1, punto gestion 2
    //   axios.get("/api/lista/25").then((response) => {
    //     this.listasForms.sitio_types = response.data;
    //   });
    // },

    seleccionarSitio() {
      if (this.form.sitio) {
        this.form.sitio_id = this.form.sitio.id;
      } else {
        this.form.sitio_id = null;
      }
    },

    seleccionarTurno() {
      if (this.form.turno) {
        this.form.turno_id = this.form.turno.id;
      } else {
        this.form.turno_id = null;
      }
    },

    // Métodos para busqueda del formulario
    buscarSitios() {
      let me = this;
      var url = "api/admin/sitios/lista";
      axios
        .get(url, {
          params: {
            estado: 1,
          },
        })
        .then(function (response) {
          let respuesta = response.data;
          me.listasForms.sitios = respuesta;
        })
        .catch(function (error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    buscarTurnos() {
      let me = this;
      var url = "api/tep/turnos/lista";
      axios
        .get(url, {
          params: {
            estado: 1,
          },
        })
        .then(function (response) {
          let respuesta = response.data;
          me.listasForms.turnos = respuesta;
        })
        .catch(function (error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    buscarFormatosInspeccion(search, loading) {
      if (search != "") {
        // Pregunto si es preoperacional
        let sitio = null;
        let pre_operacional = 0;
        let params = null;

        if (this.preoperacional) {
          pre_operacional = 1;
        } else {
          if (this.form.sitio) {
            sitio = this.form.sitio.id;
          }
        }
        // Pregunto si tiene un punto de gestión asignado

        params = {
          sitio,
          pre_operacional,
        };

        let me = this;
        loading(true);
        var url =
          "api/tep/inspecciones/buscarformatosinspeccion?nombre=" + search;
        axios
          .get(url, { params })
          .then(function (response) {
            me.listasForms.formatos_inspeccion = response.data;
            loading(false);
          })
          .catch(function (error) {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    buscarvehiculos(search, loading) {
      this.fechaActual = moment().format("YYYY-MM-DD HH:mm:ss");
      this.fechaCorte = moment(this.fechaActual)
        .add(-2, "hours")
        .format("YYYY-MM-DD HH:mm:ss");
      if (search != "") {
        let operacion = 0;
        if (this.preoperacional) {
          operacion = 1;
        }
        let me = this;
        loading(true);
        var url = "api/tep/inspecciones/buscarvehiculo?placa=" + search;
        axios
          .get(url, {
            params: {
              operacion,
            },
          })
          .then(function (response) {
            me.listasForms.vehiculos = response.data;
            loading(false);
          })
          .catch(function (error) {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    // validaOperacional() {
    //   this.limpiarCampos();
    //   if (!this.form.formato_inspeccion) {
    //     this.preoperacional = true;
    //   } else if (this.form.formato_inspeccion.pre_operacional) {
    //     this.preoperacional = true;
    //   } else {
    //     this.preoperacional = false;
    //   }
    // },

    buscarEmpresas(search, loading) {
      this.listasForms.conductores = [];
      this.form.conductor = [];
      if (search != "") {
        let me = this;
        loading(true);
        var url = "api/admin/empresas/lista?razon_social=" + search;
        axios
          .get(url)
          .then(function (response) {
            let respuesta = response.data;
            me.listasForms.empresas = respuesta;
            loading(false);
          })
          .catch(function (error) {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    buscarConductor(search, loading) {
      if (search != "") {
        let me = this;
        loading(true);
        var url = "api/admin/conductores/lista?nombre=" + search;
        axios
          .get(url, {
            params: {},
          })
          .then(function (response) {
            let respuesta = response.data;
            me.listasForms.conductores = respuesta;
            loading(false);
          })
          .catch(function (error) {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    save() {
      if (!this.$v.form.$invalid) {
        let data = this.getData();
        axios({
          method: "PUT",
          url: "/api/tep/inspecciones",
          data: data,
        })
          .then((response) => {
            this.$route.params.inspeccion_id = response.data.id;
            this.getIndex();
            this.$swal({
              icon: "success",
              title: "Los datos de la inspección se guardaron exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          })
          .catch((e) => {
            this.$swal({
              icon: "error",
              title: "Ocurrió un error, vuelva a intentarlo..." + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    getData() {
      // Se valida la fecha y hora de inicio de la insspección
      let fecha_inicio = this.form.fecha_inicio;
      let hora_inicio = this.form.hora_inicio;
      if (!this.form.fecha_inicio && !this.form.hora_inicio) {
        let ahora = new Date();
        let fecha = `${ahora.getFullYear()}-${
          ahora.getMonth() + 1
        }-${ahora.getDate()}`;
        let hora = `${ahora.getHours()}:${ahora.getMinutes()}:${ahora.getSeconds()}`;
        fecha_inicio = fecha;
        hora_inicio = hora;
      }

      let data = {
        id: this.form.id,
        tep_viaje_id: this.form.tep_viaje_id,
        tep_turno_id: this.form.turno_id,
        formato_inspeccion_id: this.form.formato_inspeccion.id,
        sitio_type: "App\\Sitio",
        sitio_id: this.form.sitio_id,
        empresa_id: this.form.empresa.id,
        conductor_id: this.form.conductor ? this.form.conductor.id : "",
        vehiculo_id: this.form.vehiculo.id,
        fecha_inicio,
        hora_inicio,
        fecha_fin: this.form.fecha_fin,
        hora_fin: this.form.hora_fin,
        observaciones: this.form.observaciones,
        responsable_id: this.form.responsable_id,
      };
      return data;
    },

    cambiaTab(tab) {
      this.tab = tab;
    },

    back() {
      return this.$router.replace("/Tep/Inspecciones");
    },

    irTepTurnos() {
      return this.$router.replace("/Tep/Turnos");
    },
  },

  mounted() {
    this.getIndex();
    this.buscarTurnos();
    this.buscarSitios();
  },
};
</script>
